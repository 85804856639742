import React from "react"
export const Banner50DataSource = {
  wrapper: { className: "home-page-wrapper banner5" },
  page: { className: "home-page banner5-page" },
  childWrapper: {
    className: "banner5-title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Send Out Interviews</p>
          </span>
        ),
        className: "banner5-title",
      },
      {
        name: "explain",
        className: "banner5-explain",
        children: (
          <span>
            <span>
              <span>
                <p>Eliminate toolchain friction.&nbsp;</p>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: "content",
        className: "banner5-content",
        children: (
          <span>
            <p>Give your team a seamless experience.&nbsp;</p>
          </span>
        ),
      },
      {
        name: "button",
        className: "banner5-button-wrapper",
        children: {
          href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
          className: "banner5-button",
          type: "primary",
          children: (
            <span>
              <p>Start Now</p>
            </span>
          ),
        },
      },
    ],
  },
  image: {
    className: "banner5-image kevlnw8x368-editor_css",
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/schedule-sendout.gif",
  },
}
export const Content00DataSource = {
  wrapper: { className: "home-page-wrapper content0-wrapper" },
  page: { className: "home-page content0" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Schedule, Interview, and Improve</p>
          </span>
        ),
      },
    ],
  },
  childWrapper: {
    className: "content0-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/WBnVOjtIlGWbzyQivuyq.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <span>
                    <p>Schedule</p>
                  </span>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <span>
                    <p>
                      Streamline the scheduling process and provide automatic
                      followups to reduce ghosting rates.&nbsp;
                    </p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block1",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/YPMsLQuCEXtuEkmXTTdk.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Interview</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Prep key authorities with candidate documents, so everyone
                    is on the same page during the interview.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
      {
        name: "block2",
        className: "content0-block",
        md: 8,
        xs: 24,
        children: {
          className: "content0-block-item",
          children: [
            {
              name: "image",
              className: "content0-block-icon",
              children:
                "https://zos.alipayobjects.com/rmsportal/EkXWVvAaFJKCzhMmQYiX.png",
            },
            {
              name: "title",
              className: "content0-block-title",
              children: (
                <span>
                  <p>Improve</p>
                </span>
              ),
            },
            {
              name: "content",
              children: (
                <span>
                  <p>
                    Recorded Interviews means that your entire team can learn
                    from the interviewing process and gain unique
                    insights.&nbsp;
                  </p>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
}
export const Content10DataSource = {
  wrapper: { className: "home-page-wrapper content1-wrapper" },
  OverPack: { className: "home-page content1", playScale: 0.3 },
  imgWrapper: { className: "content1-img", md: 10, xs: 24 },
  img: {
    children:
      "https://deephire.s3.amazonaws.com/websiteAssets/undraw_schedule_pnbk.svg",
  },
  textWrapper: { className: "content1-text", md: 14, xs: 24 },
  title: {
    className: "content1-title",
    children: (
      <span>
        <p>Streamline the Scheduling Process</p>
      </span>
    ),
  },
  content: {
    className: "content1-content",
    children: (
      <span>
        <p>
          Set up interviews for stakeholders and hiring authorities with just a
          few clicks. Schedule, manage, and view interviews from one platform.
          Automatic invitations and reminders means reduced ghosting rates, and
          increased candidate/client satisfaction.&nbsp;
        </p>
      </span>
    ),
  },
}
export const Feature20DataSource = {
  wrapper: { className: "home-page-wrapper content2-wrapper" },
  OverPack: { className: "home-page content2", playScale: 0.3 },
  imgWrapper: { className: "content2-img", md: 10, xs: 24 },
  img: {
    children:
      "https://deephire.s3.amazonaws.com/misc/mikey-vid-interview-landing.png",
    className: "kevhjh8q5rk-editor_css",
  },
  textWrapper: { className: "content2-text", md: 14, xs: 24 },
  title: {
    className: "content2-title",
    children: (
      <span>
        <p>Reduce Fraud in the Interviewing Process</p>
      </span>
    ),
  },
  content: {
    className: "content2-content",
    children: (
      <span>
        <p>
          Attach core documents like an ID, only viewable by team members.
          Reduce potential fraud in the interviewing process so that everyone is
          on the same page.&nbsp;
        </p>
      </span>
    ),
  },
}
export const Content30DataSource = {
  wrapper: { className: "home-page-wrapper content3-wrapper" },
  page: { className: "home-page content3" },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>Streamline your recruiting workflows.&nbsp;</p>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        className: "title-content",
        children: (
          <span>
            <p>
              One platform for interviewing, collaborating, and hiring.&nbsp;
            </p>
          </span>
        ),
      },
    ],
  },
  block: {
    className: "content3-block-wrapper",
    children: [
      {
        name: "block0",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ScHBSdwpTkAHZkJ.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <p>One Click Interviews</p>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <p>
                    Candidates never have to use a mobile app to take
                    interviews, meaning a more inclusive and frictionless
                    process.{" "}
                  </p>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block1",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/NKBELAOuuKbofDD.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <p>24/7 Tech Support</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      We handle all tech support, so your team won't have to.
                      With 24/7 chat, all candidates get white glove service.{" "}
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block2",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/xMSBjgxBhKfyMWX.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <span>
                    <p>Auto Invitations</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Working on a large hiring project? Invite all candidates
                      through DeepHire to save time on cold calling.{" "}
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block3",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/MNdlBNhmDBLuzqp.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <p>Built In Interview Prep</p>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Give candidates a leg up in their interviews by preloading
                      example interview questions + answers.{" "}
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block4",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/UsUmoBRyLvkIQeO.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <p>Continuous Improvement</p>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      Fine tune your interviewing process over time to ensure
                      each project is ROI positive with our reporting +
                      analytics.{" "}
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: "block5",
        className: "content3-block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "content3-icon",
            children:
              "https://zos.alipayobjects.com/rmsportal/ipwaQLBLflRfUrg.png",
          },
          textWrapper: { className: "content3-text" },
          title: {
            className: "content3-title",
            children: (
              <span>
                <span>
                  <p>Candidate Feedback</p>
                </span>
              </span>
            ),
          },
          content: {
            className: "content3-content",
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        Improve candidate satisfaction by listening to post
                        interview feedback - collected for you by
                        DeepHire.&nbsp;
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
}
export const Content110DataSource = {
  OverPack: {
    className: "home-page-wrapper content11-wrapper",
    playScale: 0.3,
  },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "image",
        children:
          "https://gw.alipayobjects.com/zos/rmsportal/PiqyziYmvbgAudYfhuBr.svg",
        className: "title-image",
      },
      {
        name: "title",
        children: (
          <span>
            <span>
              <span>
                <p>See How DeepHire can simplify your process</p>
              </span>
            </span>
          </span>
        ),
        className: "title-h1",
      },
      {
        name: "content",
        children: (
          <span>
            <span>
              <p>Save time, remove headaches.</p>
            </span>
          </span>
        ),
        className: "title-content",
      },
    ],
  },
  button: {
    className: "",
    children: {
      a: {
        className: "button",
        href: "https://recruiter.deephire.com/user/login?signupEmail=%20",
        children: (
          <span>
            <p>Start Now</p>
          </span>
        ),
      },
    },
  },
}
